import classNames from 'classnames';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { Fragment, HTMLAttributeAnchorTarget, ReactElement, ReactNode, useRef, useState } from 'react';
import Dropdown from '@components/utilities/Dropdown/Dropdown';
import DropdownItem from '@components/utilities/DropdownItem/DropdownItem';
import CustomControlB2bAdmin from '@components/utilities/NextSelect/components/CustomControl/CustomControlB2bAdmin';
import NextSelect from '@components/utilities/NextSelect/NextSelect';
import { SelectOptionType } from '@components/utilities/Select/Select';
import { useActiveBreakpoint } from '@hooks/useActiveBreakpoint';
import { useOnClickOutside } from '@hooks/useClickOutside';
import { useUser } from '@hooks/useUser';
import { useGetLocalizedRoute } from '@libs/localizedRoute';
import { Route } from '@libs/routes';
import { getUrlWithParameters } from '@libs/urlUtils';
import classes from './HamburgerMenu.module.scss';

export enum MenuItemTypes {
  Line = 'line',
  Link = 'link',
  Select = 'select',
}

export type MenuItemLineType = {
  type: MenuItemTypes.Line;
};

export type MenuItemLinkType = {
  type: MenuItemTypes.Link;
  title: ReactNode;
  url: string;
  rel?: string;
  disabled?: boolean;
  isLinkPrimary?: boolean;
  onClick?: () => void;
  dataCyItem?: string;
  isInGroup?: boolean;
  isSelected?: boolean;
  target?: HTMLAttributeAnchorTarget;
  dataIcom?: string;
};

export type MenuItemSelectType = {
  type: MenuItemTypes.Select;
  id: string;
  value: SelectOptionType | null;
  options: SelectOptionType[];
  onChange: (value: any) => void;
  defaultOptions?: SelectOptionType[];
  name: string;
};

export type MenuItemUnionType = MenuItemLinkType | MenuItemLineType | MenuItemSelectType;

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  menuItems: MenuItemUnionType[];
  icon: React.ReactNode;
}

const HamburgerMenu = ({ className, menuItems, icon, ...divProps }: Props) => {
  const { isDesktop } = useActiveBreakpoint();
  const ref = useRef(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation(['messages']);
  const getLocalizedRoute = useGetLocalizedRoute();
  const user = useUser();
  const router = useRouter();

  useOnClickOutside(ref, () => setMenuOpen(false));

  const getLoginWithPasswordRouteWithTargetPath = (): string => {
    if (router.pathname === Route.loginWithPassword) {
      return getLocalizedRoute(Route.loginWithPassword);
    }

    return getUrlWithParameters(getLocalizedRoute(Route.loginWithPassword), { targetPath: router.asPath });
  };

  const handleDropdownItemClick = (linkItem: MenuItemLinkType) => {
    if (linkItem.onClick) {
      linkItem.onClick();
    }
  };

  const renderMenuItem = (item: MenuItemUnionType) => {
    switch (item.type) {
      case MenuItemTypes.Line:
        return <hr />;
      case MenuItemTypes.Link:
        return (
          <NextLink href={item.url} rel={item.rel} className={classes.HamburgerMenu__link} target={item.target}>
            <DropdownItem
              onClick={() => handleDropdownItemClick(item)}
              disabled={item.disabled}
              className={item.isLinkPrimary ? classes.HamburgerMenu__itemLinkPrimary : ''}
              dataCyItem={item.dataCyItem}
              isInGroup={item.isInGroup}
              isSelected={item.isSelected}
              data-icom={item.dataIcom}
            >
              {item.title}
            </DropdownItem>
          </NextLink>
        );
      case MenuItemTypes.Select:
        return (
          <NextSelect
            id={item.id}
            onChange={item.onChange}
            customComponents={{ Control: CustomControlB2bAdmin }}
            options={item.options}
            name={item.name}
            isClearable={false}
            value={item.value}
            blurInputOnSelect
            placeholder={''}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {user || !isDesktop ? (
        <div
          className={classNames(className, classes.HamburgerMenu)}
          {...divProps}
          ref={ref}
          data-cy="hamburger-menu-container"
          data-icom="hamburger-menu-container"
        >
          {React.cloneElement(icon as ReactElement, {
            onClick: () => setMenuOpen((prevState) => !prevState),
            isMenuOpen,
          })}

          {isMenuOpen && (
            <Dropdown>
              {menuItems.map((menuItem, index) => (
                <Fragment key={`navbarMenu-item-${index}`}>{renderMenuItem(menuItem)}</Fragment>
              ))}
            </Dropdown>
          )}
        </div>
      ) : (
        <a href={getLoginWithPasswordRouteWithTargetPath()} rel={'nofollow'}>
          {t('LOGIN_BUTTON_LABEL')}
        </a>
      )}
    </>
  );
};

export default HamburgerMenu;
