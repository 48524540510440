import { useTranslation } from 'next-i18next';
import React, { createRef } from 'react';
import Select, {
  CSSObjectWithLabel,
  OptionsOrGroups,
  SelectComponentsConfig,
  Props as StateManagerProps,
  StylesConfig,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import CustomClearIndicator from '@components/utilities/NextSelect/components/CustomClearIndicator/CustomClearIndicator';
import CustomControl from '@components/utilities/NextSelect/components/CustomControl/CustomControl';
import CustomDropdownIndicator from '@components/utilities/NextSelect/components/CustomDropdownIndicator/CustomDropdownIndicator';
import CustomIndicatorsContainer from '@components/utilities/NextSelect/components/CustomIndicatorsContainer/CustomIndicatorsContainer';
import CustomInput from '@components/utilities/NextSelect/components/CustomInput/CustomInput';
import CustomMultiValueRemove from '@components/utilities/NextSelect/components/CustomMultiValueRemove/CustomMultiValueRemove';
import CustomOption from '@components/utilities/NextSelect/components/CustomOption/CustomOption';
import assertNever from '@libs/assertNever';
import classes from './NextSelect.module.scss';

export type BaseOptionType = {
  label: string;
  value: string;
};

type DropdownIndicatorType = 'filled' | 'outline';

interface Props extends StateManagerProps {
  loadOptions?: (inputValue: string, callback: (options: OptionsOrGroups<any, any>) => void) => void;
  defaultOptions?: OptionsOrGroups<any, any>;
  customMultiValueAddMorePlaceholder?: string;
  isCreatable?: boolean;
  showDropdownIndicatorType?: DropdownIndicatorType;
  customComponents?: SelectComponentsConfig<any, any, any>;
  styles?: StylesConfig;
}

const creatableRef = createRef<any>();

const handleCreatableBlur = (event: React.FocusEvent<HTMLInputElement>) => {
  if (event.target?.tagName === 'INPUT' && event.target.value !== '') {
    const newValue = event.target.value;

    if (creatableRef.current && creatableRef.current.blockOptionHover) {
      if (creatableRef.current.getValue().find((option: any) => option.label === newValue) === undefined) {
        creatableRef.current.selectOption({ label: event.target.value, value: event.target.value });
      }
    }
  }
};

const NextSelect = ({
  loadOptions,
  defaultOptions,
  isCreatable,
  showDropdownIndicatorType,
  customComponents,
  styles,
  ...selectProps
}: Props) => {
  const { t } = useTranslation(['messages']);

  const customStyles: StylesConfig = {
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: (providedStyles: CSSObjectWithLabel) => ({
      ...providedStyles,
      marginTop: '4px',
    }),
    menuList: (providedStyles: CSSObjectWithLabel) => ({
      ...providedStyles,
      padding: '16px 8px',
    }),
    multiValue: (providedStyles: CSSObjectWithLabel) => ({
      ...providedStyles,
      color: '#FFFFFF',
      backgroundColor: '#FFFFFF',
    }),
    multiValueLabel: (providedStyles: CSSObjectWithLabel) => ({
      ...providedStyles,
      color: '#FFFFFF',
      backgroundColor: '#3F96E0',
      padding: '5px 8px 6px',
      borderRadius: '4px 0px 0px 4px',
      fontWeight: 500,
      fontSize: '14px',
    }),
    multiValueRemove: () => ({
      margin: 0,
    }),
    placeholder: (providedStyles: CSSObjectWithLabel) => ({
      ...providedStyles,
      color: showDropdownIndicatorType ? '#343434' : '#90A2A7', // tokens.$text-primary-default or tokens.$text-secondary-default
      fontWeight: 300,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    valueContainer: (providedStyles: CSSObjectWithLabel) => ({
      ...providedStyles,
      overflow: 'initial',
    }),
  };

  const mergedStyles = {
    ...customStyles,
    ...styles,
  };

  const customSelectProps = {
    className: classes.NextSelect,
    styles: mergedStyles,
    components: {
      Option: CustomOption,
      MultiValueRemove: CustomMultiValueRemove,
      Input: CustomInput,
      Control: CustomControl,
      ClearIndicator: CustomClearIndicator,
      DropdownIndicator: CustomDropdownIndicator,
      ...customComponents,
    },
    noOptionsMessage: () => t('NOTHING_FOUND'),
    loadingMessage: () => t('LOADING'),
    ...selectProps,
  };

  switch (showDropdownIndicatorType) {
    case undefined:
      customSelectProps.components = {
        ...customSelectProps.components,
        DropdownIndicator: null,
      };
      break;
    case 'filled':
      customSelectProps.components = {
        ...customSelectProps.components,
        DropdownIndicator: null,
        IndicatorsContainer: CustomIndicatorsContainer,
      };
      break;
    case 'outline':
      customSelectProps.components = {
        ...customSelectProps.components,
        DropdownIndicator: CustomDropdownIndicator,
      };
      break;
    default:
      assertNever(showDropdownIndicatorType);
  }

  if (isCreatable) {
    return (
      <CreatableSelect
        {...customSelectProps}
        ref={creatableRef}
        formatCreateLabel={(label) => t('NEXT_SELECT_CREATABLE_LABEL', { keyword: label })}
        createOptionPosition="first"
        onBlur={(input) => {
          if (selectProps.onBlur) {
            selectProps.onBlur(input);
          }
          handleCreatableBlur(input);
        }}
      />
    );
  }

  if (loadOptions || defaultOptions) {
    return <AsyncSelect {...customSelectProps} loadOptions={loadOptions} defaultOptions={defaultOptions} />;
  }

  return <Select {...customSelectProps} />;
};

export default NextSelect;
